import Chat from '@/views/Pages/Developers/ChannelIntegration/WhatsAppChannel/Chat/Chat.vue'
import SpentInbox from '@/views/Pages/Developers/ChannelIntegration/WhatsAppChannel/SpentInbox/SpentInbox.vue'
import InboxConfig from '@/views/Pages/Developers/ChannelIntegration/WhatsAppChannel/InboxConfig/InboxConfig.vue'
import Contacts from '@/views/Pages/Developers/ChannelIntegration/WhatsAppChannel/Contacts/Contacts.vue'
import EventBus from '@/util/EventBus'
import { Subscriptions } from '@/classes/helpers/Subscriptions'
import RoutesService from '@/services/whatsapp/routes.service'
import ContactEditDialog from '@/views/Pages/Developers/ChannelIntegration/WhatsAppChannel/Contacts/ContactEditDialog.vue'
import AiAssistantWhatsappInbox from '@/components/AiAssistant/AiAssistantWhatsappInbox.vue'

export default {
  name: 'WhatsappInbox',
  components: { Chat, SpentInbox, InboxConfig, Contacts, ContactEditDialog, AiAssistantWhatsappInbox },
  data () {
    return {
      showSection: 'chat',
      isFullScreen: false,
      routes: {
        list: [],
      },
      selectedUserMsisdn: '',
      selectedUserMsisdnConfig: '',
      hasRoutes: false,
    }
  },
  computed: {
    whatsappCardSubtitle () {
      switch (this.showSection) {
        case 'chat':
          return this.$t('Conversaciones')
        case 'sessions':
          return this.$t('Consumos')
        case 'inbox-config':
          return this.$t('Configuración')
        case 'contacts':
          return this.$t('Contactos')
      }
    },
  },
  created () {
    RoutesService.getCurrentUserAllRoutes()
    .then((routes) => {
      this.hasRoutes = routes.length > 0
      Subscriptions.checkSubscription(
        Subscriptions.WHATSAPP,
        () => {
          routes.length
            ? this.$router.push({ name: this.$route.name, query: this.$route.query }).catch(() => { this.$forceUpdate() })
            : this.$router.push({ name: 'AssistantPage' }).catch(() => { this.$forceUpdate() })
        },
        () => this.$router.push({ name: 'WhatsappSubscriptionNeeded' }).catch(() => { this.$forceUpdate() }),
      )
    })
    EventBus.$on('loadChatWhatsappInbox', () => {
      this.loadChat()
    })
  },
  mounted () {
    if (this.hasRoutes) {
      this.getCurrentUserRoutes()
    }
    window.addEventListener('keydown', this.checkKeyboardShortcuts)
  },

  unmounted () {
    window.removeEventListener('keydown', this.checkKeyboardShortcuts)
  },
  methods: {
    checkKeyboardShortcuts (event) {
      // NOTE: metaKey == Command on Mac  || event.ctrlKey
      if (event.metaKey) {
        event.preventDefault()
        if (event.keyCode === 'F'.charCodeAt(0)) {
          this.toggleFullScreen()
        } else if (event.keyCode === 'R'.charCodeAt(0)) {
          EventBus.$emit('Whatsapp:toggleConversationRead')
        }
      } else if (event.ctrlKey && event.altKey) {
        event.preventDefault()
        if (event.keyCode === 'F'.charCodeAt(0)) {
          this.toggleFullScreen()
        } else if (event.keyCode === 'R'.charCodeAt(0)) {
          EventBus.$emit('Whatsapp:toggleConversationRead')
        }
      }
    },
    setSelectedUserMsisdn (msisdn) {
      this.selectedUserMsisdn = msisdn
    },
    getCurrentUserRoutes () {
      RoutesService.getCurrentUserRoutes()
      .then((routes) => {
        this.routes.list = routes
        this.selectedUserMsisdnConfig = this.routes.list[0].msisdn
      })
    },
    loadChat () {
      // EventBus.$emit('whatsapp-inbox-update-conversarions')
      this.showSection = 'chat'
    },
    loadContacts () {
      EventBus.$emit('whatsapp-inbox-update-contacts')
      this.showSection = 'contacts'
    },
    toggleFullScreen () {
      if (this.$refs.inbox.classList.value.includes('fullscreen')) {
        this.$refs.inbox.classList.remove('fullscreen')
        this.$refs.inbox.classList.add('windowed')
        this.isFullScreen = false
      } else {
        this.$refs.inbox.classList.add('fullscreen')
        this.$refs.inbox.classList.remove('windowed')
        this.isFullScreen = true
      }
      EventBus.$emit('chatWindow:toggleFullscreen', this.isFullScreen)
    },
  },
}
