var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"px-0 ",attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-1 pt-10 mt-12"},[_c('v-col',{ref:"inbox",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"v-card-grey mb-1 pb-0 px-0 "},[_c('v-card-title',{staticClass:"pt-0 pb-0 mb-0 card-title-bloque"},[_c('div',{staticClass:"w100 d-flex"},[(_vm.isFullScreen)?_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/logos/logo_isotipo.svg")}}):_vm._e(),_c('p',{staticClass:"tit_card pt-2"},[_vm._v(" "+_vm._s(_vm.$t('WhatsApp Inbox'))+" "),_c('span',{staticClass:"subtit_card"},[_vm._v(" "+_vm._s(_vm.whatsappCardSubtitle)+" ")])]),_c('v-tooltip',{staticStyle:{"z-index":"1000"},attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-0 mb-0 ml-2 light-blue--text text--darken-2 mt-1",staticStyle:{"border":"solid 1px #e9eff1 !important"},attrs:{"color":"white","elevation":"0","outlined":"","fab":"","small":""},on:{"click":function($event){return _vm.toggleFullScreen()}}},'v-btn',attrs,false),on),[(_vm.isFullScreen)?_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" fas fa-compress ")]):_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" fas fa-expand ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Pantalla completa')))])]),_c('div',{staticClass:"buttons-container d-flex justify-end flex-grow-1 mr-10",staticStyle:{"z-index":"0"}},[(_vm.showSection === 'inbox-config')?_c('div',{staticClass:"select-msisdn"},[_c('v-select',{staticClass:"pt-2 px-4 oulined-input",style:({ height: '30px' }),attrs:{"items":_vm.routes.list,"label":_vm.$t('Selecciona un remitente'),"item-text":"displayName","item-value":"msisdn","elevation":"0","solo":""},model:{value:(_vm.selectedUserMsisdnConfig),callback:function ($$v) {_vm.selectedUserMsisdnConfig=$$v},expression:"selectedUserMsisdnConfig"}})],1):_vm._e(),_c('v-btn-toggle',{staticClass:"pb-1",attrs:{"rounded":"","mandatory":""}},[_c('v-btn',{staticClass:"px-7",staticStyle:{"border":"solid 1px #e9eff1 !important"},attrs:{"fab":"","small":"","color":"white","elevation":"0"},on:{"click":_vm.loadChat}},[_c('v-tooltip',{staticClass:"custom-tooltip-z-index",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"btn-submenu-barra",class:{'selected': _vm.showSection === 'chat'}},'v-icon',attrs,false),on),[_vm._v(" far fa-comments ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Conversaciones"))+" ")])])],1),_c('v-btn',{staticClass:"px-7",staticStyle:{"border":"solid 1px #e9eff1 !important"},attrs:{"fab":"","small":"","color":"white","elevation":"0"},on:{"click":_vm.loadContacts}},[_c('v-tooltip',{staticClass:"custom-tooltip custom-tooltip-z-index",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"btn-submenu-barra ",class:{'selected': _vm.showSection === 'contacts'}},'v-icon',attrs,false),on),[_vm._v(" fas fa-id-badge ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Contactos"))+" ")])])],1),_c('v-btn',{staticClass:"px-7",staticStyle:{"border":"solid 1px #e9eff1 !important"},attrs:{"fab":"","small":"","color":"white","elevation":"0"},on:{"click":function($event){_vm.showSection = 'sessions'}}},[_c('v-tooltip',{staticClass:"custom-tooltip custom-tooltip-z-index",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"btn-submenu-barra",class:{'selected': _vm.showSection === 'sessions'}},'v-icon',attrs,false),on),[_vm._v(" fas fa-chart-pie ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Consumos"))+" ")])])],1),_c('v-btn',{staticClass:"px-7",staticStyle:{"border":"solid 1px #e9eff1 !important"},attrs:{"fab":"","small":"","color":"white","elevation":"0"},on:{"click":function($event){_vm.showSection = 'inbox-config'}}},[_c('v-tooltip',{staticClass:"custom-tooltip custom-tooltip-z-index",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"btn-submenu-barra",class:{'selected': _vm.showSection === 'inbox-config'}},'v-icon',attrs,false),on),[_vm._v(" fas fa-cogs ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Configuración')))])])],1)],1)],1)],1)]),_c('v-card-text',{staticClass:"px-0 py-0 my-0"},[_c('chat',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSection === 'chat'),expression:"showSection === 'chat'"}],attrs:{"routes":_vm.routes},on:{"change-user-msisdn":_vm.setSelectedUserMsisdn}}),_c('spent-inbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSection === 'sessions'),expression:"showSection === 'sessions'"}]}),_c('inbox-config',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSection === 'inbox-config'),expression:"showSection === 'inbox-config'"}],attrs:{"selected-user-msisdn-config":_vm.selectedUserMsisdnConfig}}),_c('contacts',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSection === 'contacts'),expression:"showSection === 'contacts'"}],attrs:{"routes":_vm.routes,"selected-user-msisdn":_vm.selectedUserMsisdn}})],1)],1)],1)],1)],1),_c('contact-edit-dialog',{attrs:{"selected-user-msisdn":_vm.selectedUserMsisdn}}),_c('ai-assistant-whatsapp-inbox')],1)}
var staticRenderFns = []

export { render, staticRenderFns }